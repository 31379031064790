module.exports = {
    SQL_CONNECTION_HOST: 'localhost',
    SQL_CONNECTION_PORT: 3306,
    SQL_CONNECTION_USER: 'root',
    SQL_CONNECTION_PASSWORD: 'root',
    SQL_CONNECTION_DATABASE: 'project',
    SQLITE_DATABASE_LOCATION: '/Users/myk/Documents/code/sqlite-unhash/unhash-project.db',
    PROJECT_TABLE: "project_config",
    PROJECT_TABLE_ID_COLUMN: "project_id",
    PROJECT_TABLE_ACTION_COLUMN: "action",
    UPDATE_TABLE: "update_config",
    UPDATE_TABLE_ID_COLUMN: "project_id",
    UPDATE_TABLE_FILE_LOCATION_COLUMN: "file_location",
    OBJECT_TABLE: "object_config",
    OBJECT_TABLE_ID_COLUMN: "object_id",
    USERS_TABLE: "users",
    USER_ID_COLUMN: "user",
    USER_PASSWORD_COLUMN: "password",
    USER_ADMIN_ACCESS_COLUMN: "admin",
    USER_PROJECT_ACCESS_COLUMN: "project_action",
    USER_UPDATE_ACCESS_COLUMN: "update_action",
    USER_OBJECT_ACCESS_COLUMN: "object_action",
    ACTION_LOOKUP: [
        { 'value':0, 'text':'Do nothing' },
        { 'value':1, 'text':'Abort' },
        { 'value':2, 'text':'Send update' },
    ],

    TABS: {
        'Project Config': 'Project',
        'Update Config': 'Update',
        'Object Config': 'Object',
        'Admin Screen': 'Admin',
    },
   
    BACKEND_CONNECTION: 'https',
    BACKEND_SERVER: 'myk.ninja',
    BACKEND_SERVER_PORT: 443,

    ADMIN_SCREEN: {
        WELCOME: "Welcome to Admin Screen",
        SEARCH_PLACEHOLDER: "Search users",
        ADMIN_LIST_SEARCH: "Users meeting search for: ",
        ADMIN_LIST_HEADER: "Users created so far:",
        EXIST_ALREADY: "This user already exists.",
        UPDATE_EXISTING: "Update this user?",
        REMOVE_EXISTING: "Remove this user?",
        ADD_NEW: "Add User with ID: ",
        PASSWORD_PLACEHOLDER: "Password",
    },
    OBJECT_SCREEN: {
        WELCOME: "Welcome to Object Screen",
        EXIST_ALREADY: "This object already exists.",
        REMOVE_EXISTING: "Remove this object?",
        ADD_NEW: "Add New Object with ID: ",
        SEARCH_PLACEHOLDER: "Search",
        OBJECT_LIST_HEADER: "Objects created so far:",
        OBJECT_LIST_SEARCH: "Objects meeting search for: "
    },
    PROJECT_SCREEN: {
        WELCOME: "Welcome to Project Screen",
        UNKNOWN_REF: "This value is unknown. To record, select action",
        EXIST_ALREADY: "This project already exists.",
        UPDATE_EXISTING: "Update the record?",
        REMOVE_EXISTING: "Remove this project?",
        ADD_NEW: "Add New Project with ID: ",
        SEARCH_PLACEHOLDER: "Search",
        PROJECT_LIST_HEADER: "Projects created so far:",
        PROJECT_LIST_SEARCH: "Projects meeting search for: "
    },
    UPDATE_SCREEN: {
        WELCOME: "Welcome to Update Screen",
        UNKNOWN_REF: "This value is unknown. To record, enter location",
        EXIST_ALREADY: "This update already exists.",
        UPDATE_EXISTING: "Update the record?",
        REMOVE_EXISTING: "Remove this update?",
        ADD_NEW: "Add New Update with ID: ",
        SEARCH_PLACEHOLDER: "Search",
        FILE_LOC_PLACEHOLDER: "File location",
        UPDATE_LIST_HEADER: "Updates created so far:",
        UPDATE_LIST_SEARCH: "Updates meeting search for: "
    },
}
